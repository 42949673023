<template>
  <div>
    <file-pond
      name="file"
      ref="filepond"
      :label-idle="uploadInstructions"
      :allow-multiple="allowMultipleFiles"
      accepted-file-types="image/jpeg, image/png"
      :server="server"
      :files="files"
      :allowFileEncode="true"
      :allowImageTransform="true"
      :allowImageCrop="true"
      v-on:processfiles="this.handleProcessed"
      v-on:init="handleFilePondInit"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex'

import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileEncode)

export default {
  name: 'FileUpload',
  components: { FilePond },
  computed: {
    url () {
      return process.env.VUE_APP_ROOT_API
    },
    withCredentials () {
      return true
    },
    allowMultipleFiles () {
      if (this.allowMultiple) {
        return this.allowMultiple
      }
      return false
    },
    server () {
      return {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          const config = {
            headers: {
              'Content-type': 'multipart/form-data'
            },
            onUploadProgress: (e) => {
              progress(e.lengthComputable, e.loaded, e.total)
            }
          }
          const data = new FormData()
          data.append('file', file, file.name)
          this.$http.post(process.env.VUE_APP_ROOT_API + 'files', data, config)
            .then(response => {
              load(response.data.id)
              this.uploadedFiles.push(response.data.id)
            })
            .catch((thrown) => {
              if (this.$http.isCancel(thrown)) {
                console.log('Request canceled', thrown.message)
              } else {
                // handle error
                console.log('an error was thrown', thrown)
              }
            })
        },
        processed: (error, file) => {
          if (error) {
            console.log('error', error)
          }
          console.log('file upload finished with file ', file)
          console.log('processing is finished.')
        },
        revert: (uniqueFileId, load, error) => {
          this.$http.delete(process.env.VUE_APP_ROOT_API + 'files/' + uniqueFileId)
        }
      }
    },
    uploadInstructions () {
      if (this.allowMultiple) {
        return 'Click to select or drop your files here...'
      } else {
        return 'Click to select or drop your file here...'
      }
    },
    ...mapState([
      'xsrfToken'
    ])
  },
  mounted () {
    console.log(document.cookie)
  },
  methods: {
    handleFilePondInit () {
      this.$emit('loading')
    },
    fileIds () {
      console.log('called fileIds')
      const fileIds = []
      if (!this.$refs.filepond) {
        return fileIds
      }
      this.$refs.filepond.getFiles().forEach((file) => {
        fileIds.push(file.serverId)
      })
      if (fileIds.length === 0) {
        return null
      }
      if (this.allowMultiple) {
        return fileIds
      } else {
        return fileIds[0]
      }
    },
    handleProcessed () {
      console.log('handle processed event fired.')
      const fileIds = this.fileIds()
      this.$emit('processed', fileIds)
    }
  },
  data () {
    return {
      files: [],
      uploadedFiles: []
    }
  },
  props: ['allowMultiple']
}
</script>

<style scoped>

</style>
